Vue.component("home-intro-slider", {
    data: function() {
        return {};
    },
    computed: {
        slides: function() {
            return this.$refs.homeIntroSlider.querySelectorAll(
                ".home-intro-slider__slide"
            );
        },
    },
    mounted: function() {
        this.initSlider();
    },
    methods: {
        initSlider() {
            this.slider = new Flickity(this.$refs.homeIntroSlider, {
                bgLazyLoad: 1,
                prevNextButtons: false,
                pageDots: false,
                wrapAround: this.slides.length > 1,
                imagesLoaded: true,
                draggable: "ontouchstart" in window
            });
        },
        nextSlide: function() {
            this.slider.next(true, false);
        },
        prevSlide: function() {
            this.slider.previous(true, false);
        },
    }
});
